import revive_payload_client_U14gk0NzuV from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@18.19.39_ioredis@5.4.1_magicast@0.3.4_rollup@4._jfdfwhiczt6wlp32domcty643u/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Vz9f6tofai from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@18.19.39_ioredis@5.4.1_magicast@0.3.4_rollup@4._jfdfwhiczt6wlp32domcty643u/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_dNk2eLRMsM from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@18.19.39_ioredis@5.4.1_magicast@0.3.4_rollup@4._jfdfwhiczt6wlp32domcty643u/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_cUgLwk4ywZ from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@18.19.39_ioredis@5.4.1_magicast@0.3.4_rollup@4._jfdfwhiczt6wlp32domcty643u/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_9UU9AEiZc6 from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@18.19.39_ioredis@5.4.1_magicast@0.3.4_rollup@4._jfdfwhiczt6wlp32domcty643u/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_cVoeL8vO1Q from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@18.19.39_ioredis@5.4.1_magicast@0.3.4_rollup@4._jfdfwhiczt6wlp32domcty643u/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_txIptngxh8 from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@18.19.39_ioredis@5.4.1_magicast@0.3.4_rollup@4._jfdfwhiczt6wlp32domcty643u/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_5kMkufbrcu from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/node_modules/.pnpm/@pinia+nuxt@0.4.11_magicast@0.3.4_rollup@4.18.0_vue@3.4.31/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/.nuxt/components.plugin.mjs";
import prefetch_client_fflCjPrVzW from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@18.19.39_ioredis@5.4.1_magicast@0.3.4_rollup@4._jfdfwhiczt6wlp32domcty643u/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_rqgIW0J6SV from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/node_modules/.pnpm/nuxt-gtag@1.2.1_magicast@0.3.4_rollup@4.18.0/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import plugin_client_ZuDYjzpsN5 from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/node_modules/.pnpm/@samk-dev+nuxt-vcalendar@1.0.3_magicast@0.3.4_rollup@4.18.0_vue@3.4.31/node_modules/@samk-dev/nuxt-vcalendar/dist/runtime/plugin.client.mjs";
import pwa_icons_plugin_LnrhIJeMG7 from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/.nuxt/pwa-icons-plugin.ts";
import pwa_client_iMbNXO5LSU from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/node_modules/.pnpm/@vite-pwa+nuxt@0.10.0_magicast@0.3.4_rollup@4.18.0_vite@5.3.3_@types+node@18.19.39_terser@5.3_r2fqgtacgtil2h2r2rqfnrp7ay/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import recording_fooFM2ZuKF from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/plugins/recording.js";
import axios_client_wXQh1dgzBD from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/plugins/axios.client.js";
import sdk_client_4T4FRaq2ER from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/plugins/mycure/sdk.client.js";
import auth_client_rVvZ8PTtTT from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/plugins/mycure/auth.client.js";
import index_client_hXUT82CNLq from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/plugins/app-strings/index.client.js";
import primevue_components_client_6CYlNjZ8lK from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/plugins/primevue-components.client.js";
import vue3lottie_client_A5ALvcprjP from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/plugins/vue3lottie.client.js";
import calcom_client_cUiblCjJea from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/plugins/calcom.client.js";
import vanilla_components_client_g5kHefiHHG from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/plugins/vanilla-components.client.js";
export default [
  revive_payload_client_U14gk0NzuV,
  unhead_Vz9f6tofai,
  router_dNk2eLRMsM,
  payload_client_cUgLwk4ywZ,
  navigation_repaint_client_9UU9AEiZc6,
  check_outdated_build_client_cVoeL8vO1Q,
  chunk_reload_client_txIptngxh8,
  plugin_vue3_5kMkufbrcu,
  components_plugin_KR1HBZs4kY,
  prefetch_client_fflCjPrVzW,
  plugin_client_rqgIW0J6SV,
  plugin_client_ZuDYjzpsN5,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_iMbNXO5LSU,
  recording_fooFM2ZuKF,
  axios_client_wXQh1dgzBD,
  sdk_client_4T4FRaq2ER,
  auth_client_rVvZ8PTtTT,
  index_client_hXUT82CNLq,
  primevue_components_client_6CYlNjZ8lK,
  vue3lottie_client_A5ALvcprjP,
  calcom_client_cUiblCjJea,
  vanilla_components_client_g5kHefiHHG
]