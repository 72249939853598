import { defineNuxtPlugin } from '#imports';
import { useRouter } from 'vue-router';

export function registerAuthGuards (router, sdk) {
  // guard must be authenticated
  router.beforeEach(async (to, from) => {
    if (!to.matched.some(record => record.meta.requiresAuth)) return;

    const currentUser = await sdk.currentUser();

    // requires authenticated
    if (!currentUser) return { name: 'index', query: { next: to.fullPath } };

    // requires email verification
    // if (!currentUser.isEmailVerified) {
    //   return { name: 'verify-email', query: { to: to.path, from: from.path } };
    // }

    // requires onboarding
    // if (!currentUser.isOnboarded) {
    //   return { name: 'onboarding/user', query: { to: to.path, from: from.path } };
    // }

    // requires service account
    if (to.matched.some(record => record.meta.requiresServiceAccount)) {
      if (!currentUser.isServiceAccount) {
        return { name: 'forbidden', query: { to: to.path, from: from.path } };
      }
    }
  });

  // guard must be unauthenticated
  router.beforeEach(async (to, from) => {
    if (!to.matched.some(record => record.meta.requiresUnauth)) return;
    if (!await sdk?.currentUser()) return;
    return { name: 'index' };
  });
}

// boot initialization
export default defineNuxtPlugin({
  setup (nuxtApp) {
    const sdk = nuxtApp.vueApp.config.globalProperties.$sdk;
    const router = useRouter();

    // register guards
    registerAuthGuards(router, sdk);
  },
});
