import Dexie from 'dexie';
import * as rrweb from 'rrweb';

export default function boot () {
  const state = {
    events: [],
    stopfn: null,
  };

  async function saveEvents (opts, events) {
    const dbname = opts?.dbname || 'rrweb';
    const shouldinitialize = !state.db;
    const db = state.db ||= new Dexie(dbname);
    if (shouldinitialize) {
      db.version(1).stores({
        events: '++id',
      });
    }

    await db.events.bulkAdd(events).catch(error => {
      console.error('Failed to save events:', error);
    });
  }

  async function downloadEvents (opts) {
    const dbname = opts?.dbname || 'rrweb';
    const db = state.db ||= new Dexie(dbname);

    const events = await db.events.toArray().catch(error => {
      console.error('Failed to read events:', error);
      return [];
    });

    // Download events as a JSON file
    const eventsJson = JSON.stringify(events, null, 2);
    const blob = new Blob([eventsJson], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${dbname}.events.json`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }

  async function startRecording (opts) {
    if (typeof opts === 'string') opts = { dbname: opts };
    opts = opts || {};
    opts.dbname = opts.dbname || 'rrweb';

    const rrwebRecordFn = rrweb.record || window.rrwebRecord;
    if (!rrwebRecordFn) {
      console.error('RRweb not found');
      return;
    }
    if (state.stopfn) {
      console.warn(`Recording already started ${state.opts.dbname}.`);
      return;
    }
    const stopfn = rrwebRecordFn({
      emit (event) {
        state.events.push(event);
      },
    });
    const save = async () => {
      if (!state.events.length) return;
      const events = state.events.slice();
      state.events = [];
      console.warn(`Saving ${events.length} events...`);
      try {
        // save events to indexdb
        await saveEvents(opts, events);

        console.info('Successfully saved events:', events.length);
      } catch (error) {
        console.error('Failed to save events:', error);
        state.events.push(...events);
      }
    };

    const saveinterval = opts?.saveinterval || 1000;
    console.info(`Recording started (${opts.dbname}). Saving events every`, saveinterval, 'ms.');
    const intervalid = setInterval(save, saveinterval);

    // Return a function to stop recording
    state.opts = { ...opts };
    state.stopfn = async () => {
      clearInterval(intervalid);
      stopfn();
      await save().catch(console.error);
      console.warn(`Recording stopped (${opts.dbname}).`);
      state.stopfn = null;
      state.opts = null;
    };
  }

  async function stopRecording () {
    if (!state.stopfn) {
      console.warn('Recording not started');
      return;
    }

    await state.stopfn();
    await downloadEvents(state.opts).catch(error => {
      console.warn('Failed to download events:', error);
      console.warn('Use indexdb exporter to download events. https://chromewebstore.google.com/detail/indexeddb-exporter/kngligbmoipnmljnpphhocajldjplgcj');
    });
  }

  window.startRecording = startRecording;
  window.stopRecording = stopRecording;
}
