
/**
 * Checks if the app is running in standalone mode.
 * by checking if the `window.__TAURI__` object exists which is injected by Tauri.
 *
 * @returns {boolean} `true` if the app is running in standalone mode, `false` otherwise.
 */
export function isStandaloneMode () {
  return !!window.__TAURI__;
  // TODO: this is a temporary workaround so we can
  // run the app in "standalone mode" in development
  // return true;
};