import { STORAGE_KEY_API } from '@/constants/sdk';
import { getCurrentInstance, toRefs, reactive } from 'vue';
import { isStandaloneMode } from '@/utils/tauri-helper';

export function useSdk () {
  const instance = getCurrentInstance();
  // console.warn('useSdk instance', instance);
  // console.count('useSdk', sdk);
  // console.warn(sdk);
  return instance?.appContext?.config?.globalProperties?.$sdk;
}

const state = reactive({
  currentUser: null,
});

export function useAuth () {
  const sdk = useSdk();
  const SERVICE_NAME = 'authentication';

  const isStandalone = isStandaloneMode();

  const signin = async (data) => sdk?.signinWithEmail(data.email, data.password, data.remoteApiUrl, data.remoteSyncForce);
  async function signinWithToken (token) {
    return sdk?.signinWithToken(token);
  }
  const signout = async () => sdk?.signout();
  const getToken = () => sdk?.token();
  const register = async (data, skipSignIn = true) => {
    await sdk?.create('accounts', data);
    if (skipSignIn) return;
    const { email, password } = data;
    await signout();
    const account = await signin({ email, password });
    return account;
  };
  const init = async () => {
    const user = await sdk?.currentUser(true);
    state.currentUser = user;
    return user;
  };
  const accountExists = async (email) => {
    const result = await sdk?.create(SERVICE_NAME, {
      action: 'checkUniqueIdentity',
      identityKey: 'email',
      identity: email,
    });
    const isUnique = result?.unique;
    return !isUnique;
  };
  const sendVerificationEmail = async (opts) => {
    const payload = {
      action: 'sendVerificationEmail',
    };
    // Only add acceptUrl if not in standalone mode
    if (!isStandalone) payload.acceptUrl = opts.acceptUrl;
    console.warn('isStandalone', isStandalone);
    console.warn('sendVerificationEmail', payload);
    return sdk?.create(SERVICE_NAME, payload);
  };
  const applyActionCode = async (opts) => {
    const payload = Object.assign({
      action: 'applyActionCode',
    }, opts);
    return sdk?.create(SERVICE_NAME, payload);
  };

  return {
    ...toRefs(state),
    accountExists,
    applyActionCode,
    getToken,
    init,
    register,
    sendVerificationEmail,
    signin,
    signinWithToken,
    signout,
  };
}

export function useSdkConstants (apiUrl) {
  const isStandalone = isStandaloneMode();

  // all available static urls
  let apiBaseUrls = [];

  if (apiUrl) {
    apiBaseUrls = [
      // ...Cookies.get(COOKIE_KEY_API_BASE_URL)?.split(',') || [],
      ...apiUrl.split(','),
    ].filter(Boolean);
  }

  // retrieve cached url
  const cachedApiUrl = globalThis.localStorage.getItem(STORAGE_KEY_API);

  // default url (cached has higher priority)
  // let apiBaseUrl = cachedApiUrl?.startsWith('http') ? cachedApiUrl : apiBaseUrls[0];
  let apiBaseUrl = apiBaseUrls?.[0] || cachedApiUrl;
  
  if (isStandalone) apiBaseUrl = 'http://localhost:7500';
  // if (isStandalone) apiBaseUrl = 'https://api.stg.easyjoey.com';

  // console.info('cookies', Cookies.getAll());
  // console.info(`cookies.${COOKIE_KEY_API_BASE_URL}`, Cookies.get(COOKIE_KEY_API_BASE_URL));
  console.info('apiBaseUrl cached', cachedApiUrl);
  console.info('apiBaseUrl candidates', apiBaseUrls);
  console.info('apiBaseUrl', apiBaseUrl);

  return {
    apiBaseUrls,
    apiBaseUrl,
  };
}
