import { default as _40406qXROBLT0Meta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/404.vue?macro=true";
import { default as verify_45emailXyqi5ODs9IMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/authentication-management/verify-email.vue?macro=true";
import { default as verify_45password_45resetWigsrPUL4WMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/authentication-management/verify-password-reset.vue?macro=true";
import { default as calendarf4NEkkyUNkMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/calendar.vue?macro=true";
import { default as billingmVPNxv6V07Meta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/consult/billing.vue?macro=true";
import { default as all_45recordsI7ZQptt7pUMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/consult/emr/all-records.vue?macro=true";
import { default as dental3DCD0qisQ8Meta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/consult/emr/dental.vue?macro=true";
import { default as pediaLWQnDBuP7xMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/consult/emr/pedia.vue?macro=true";
import { default as soapzK5fl9BIqcMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/consult/emr/soap.vue?macro=true";
import { default as emrwZzasvaO0DMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/consult/emr.vue?macro=true";
import { default as encounterVmkSKmR4F4Meta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/consult/encounter.vue?macro=true";
import { default as consultgpBfCirlkJMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/consult.vue?macro=true";
import { default as _91_91status_93_93qu3IP6ed6dMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/dashboard/bookings/[[status]].vue?macro=true";
import { default as bookings7CEKXiGargMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/dashboard/bookings.vue?macro=true";
import { default as _91_91type_93_93mnAgwZ8iepMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/dashboard/encounters/[[type]].vue?macro=true";
import { default as indexPrfZGw7anrMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/dashboard/index.vue?macro=true";
import { default as notifsWwOVAUHQceMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/dashboard/notifs.vue?macro=true";
import { default as _91_91id_93_93z5hILKo6X3Meta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/dashboard/patient/[[id]].vue?macro=true";
import { default as constantsefke7Sc4OWMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/dashboard/patient/form/constants.js?macro=true";
import { default as indexSfClL4IUn9Meta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/dashboard/patient/form/index.vue?macro=true";
import { default as indexxDHH9SwRCoMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/dashboard/patients/index.vue?macro=true";
import { default as indexSJJHn2PvcgMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/dashboard/shared/index.vue?macro=true";
import { default as downloadPNDcoXMHLoMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/download.vue?macro=true";
import { default as indexv7dhiB8enxMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/encounter/assessment/index.vue?macro=true";
import { default as indexSMBdM3C3tjMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/encounter/billing/index.vue?macro=true";
import { default as index28lAxDhS7WkMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/encounter/billing/index2.vue?macro=true";
import { default as indexDM6dW0AQ8UMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/encounter/consult-notes/index.vue?macro=true";
import { default as indexAfjdNxx5ZjMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/encounter/intake-form/index.vue?macro=true";
import { default as indexyOeGVuYOuZMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/encounter/laboratory/index.vue?macro=true";
import { default as indexwhMZ0NBrc7Meta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/encounter/objective/index.vue?macro=true";
import { default as indexpXYW4iikZiMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/encounter/plan/index.vue?macro=true";
import { default as index8OdF7UCsILMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/encounter/radiology/index.vue?macro=true";
import { default as index9bTDMCdprAMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/encounter/subjective/index.vue?macro=true";
import { default as ai_45summaryMbgTVP6cTjMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/encounter/summary/ai-summary.vue?macro=true";
import { default as dental_45tabrGBExuS5C7Meta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/encounter/summary/dental-tab.vue?macro=true";
import { default as encounter_45tabQjU19kbyuEMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/encounter/summary/encounter-tab.vue?macro=true";
import { default as indexUHPWHmwuAYMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/encounter/summary/index.vue?macro=true";
import { default as mhx_45summary_45tabItDABK4mQ5Meta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/encounter/summary/mhx-summary-tab.vue?macro=true";
import { default as pedia_45tabQBQpyZAX4QMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/encounter/summary/pedia-tab.vue?macro=true";
import { default as index759Jch4cnEMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/encounter/triage/index.vue?macro=true";
import { default as encounterM90iQRRVrnMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/encounter.vue?macro=true";
import { default as forgot_45passwordc6d7lktoOHMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/forgot-password.vue?macro=true";
import { default as indexrlfNtNAlWgMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/index.vue?macro=true";
import { default as additional_45informationzejJdyYjoLMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/intake-form/additional-information.vue?macro=true";
import { default as consent_45formKPfPXIxdJ2Meta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/intake-form/consent-form.vue?macro=true";
import { default as constantsbWBeAenPuZMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/intake-form/constants.js?macro=true";
import { default as contact_45informationsAz5oQCTkUMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/intake-form/contact-information.vue?macro=true";
import { default as email_45addressbLEQlg2gxhMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/intake-form/email-address.vue?macro=true";
import { default as handle_45fileW6aZyRhicYMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/intake-form/handle-file.js?macro=true";
import { default as indexv5PdwJnhhfMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/intake-form/index.vue?macro=true";
import { default as medical_45historyZLNelSy74FMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/intake-form/medical-history.vue?macro=true";
import { default as personal_45detailszsP392lzh7Meta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/intake-form/personal-details.vue?macro=true";
import { default as privacy_45noticeaIFbb7UvIuMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/intake-form/privacy-notice.vue?macro=true";
import { default as intake_45formMckiWFXr8mMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/intake-form.vue?macro=true";
import { default as composableshLhpajUMOXMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/modules/billing/composables.js?macro=true";
import { default as indexELEiXuj1P2Meta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/modules/billing/index.vue?macro=true";
import { default as transactionsPuW3Klia3tMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/modules/billing/transactions.vue?macro=true";
import { default as modulesH4ywRr4tTVMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/modules.vue?macro=true";
import { default as doctor_45infoCC0asVN0WoMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/onboarding/doctor-info.vue?macro=true";
import { default as specializationye1QR20oWIMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/onboarding/specialization.vue?macro=true";
import { default as userKWgNZW12eOMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/onboarding/user.vue?macro=true";
import { default as demoSJCYAZz90sMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/patient/demo.vue?macro=true";
import { default as invoicesqj1sW8kUrMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/print/invoice.vue?macro=true";
import { default as prescription8btJ9PDmSOMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/print/prescription.vue?macro=true";
import { default as printi4ti3wIy2MMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/print.vue?macro=true";
import { default as educationYKAHxRffsiMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/settings/account/education.vue?macro=true";
import { default as personalvwR7YpnfHiMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/settings/account/personal.vue?macro=true";
import { default as professionalTbM3V9JnRBMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/settings/account/professional.vue?macro=true";
import { default as securityLyKJJoGVkYMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/settings/account/security.vue?macro=true";
import { default as accountFReVfTGqhCMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/settings/account.vue?macro=true";
import { default as discountsO2z6o9U1WNMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/settings/billing/discounts.vue?macro=true";
import { default as promosqk5EqiLIc9Meta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/settings/billing/promos.vue?macro=true";
import { default as servicesY5NavPOaszMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/settings/billing/services.vue?macro=true";
import { default as stripe_45connect5LaAj6oHcDMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/settings/billing/stripe-connect.vue?macro=true";
import { default as billingzWrfySpzXiMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/settings/billing.vue?macro=true";
import { default as availability6LHGFC29H6Meta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/settings/booking/availability.vue?macro=true";
import { default as eventsBo18DWAVcIMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/settings/booking/events.vue?macro=true";
import { default as setupW05URcyJMfMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/settings/booking/setup.vue?macro=true";
import { default as testgEqVSrg8KLMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/settings/booking/test.vue?macro=true";
import { default as bookingelyRWyRK9zMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/settings/booking.vue?macro=true";
import { default as detailsWJbukB7DpWMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/settings/clinic/details.vue?macro=true";
import { default as licensesxrsd4oWY5QMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/settings/clinic/licenses.vue?macro=true";
import { default as membersbpJqSYUvhBMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/settings/clinic/members.vue?macro=true";
import { default as clinicjtxYkbw1iZMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/settings/clinic.vue?macro=true";
import { default as custom_45statusesuxSigvAOfeMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/settings/dental/custom-statuses.vue?macro=true";
import { default as default_45statusesIW2ie1H61gMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/settings/dental/default-statuses.vue?macro=true";
import { default as dentalW7JgYA9ZK5Meta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/settings/dental.vue?macro=true";
import { default as indexlAJWMOqqPPMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/settings/diagnostic/index.vue?macro=true";
import { default as lab_45testscVsoXxiFOoMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/settings/diagnostic/lab-tests.vue?macro=true";
import { default as radiology_45testsEcZK3g3PqpMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/settings/diagnostic/radiology-tests.vue?macro=true";
import { default as diagnosticInbwWdnmamMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/settings/diagnostic.vue?macro=true";
import { default as constantsETe1mhWxSTMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/settings/lab/constants.js?macro=true";
import { default as testsYsDALfkIPaMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/settings/lab/tests.vue?macro=true";
import { default as favorite_45prescriptionsKRgr4DVaPmMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/settings/medical-records/favorite-prescriptions.vue?macro=true";
import { default as medical_45recordsoBWiMotNVKMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/settings/medical-records.vue?macro=true";
import { default as constantszzy21bD0ZRMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/settings/radiology/constants.js?macro=true";
import { default as testsePPJ4yKCnSMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/settings/radiology/tests.vue?macro=true";
import { default as settingsSgKLTUvuU9Meta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/settings.vue?macro=true";
import { default as sharingSWHKmhYbcKMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/sharing.vue?macro=true";
import { default as indexANyRZOU74aMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/signup/index.vue?macro=true";
import { default as quickV5tIrb2Gt0Meta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/signup/quick.vue?macro=true";
import { default as splashscreenHTw870FgbZMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/splashscreen.vue?macro=true";
import { default as authenticate1w0japLOuPMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/standalone/authenticate.vue?macro=true";
import { default as signup15RzATsOKoMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/standalone/signup.vue?macro=true";
import { default as teleconsultVypDNCKZ2jMeta } from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/teleconsult.vue?macro=true";
export default [
  {
    name: "404",
    path: "/404",
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/404.vue").then(m => m.default || m)
  },
  {
    name: "authentication-management-verify-email",
    path: "/authentication-management/verify-email",
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/authentication-management/verify-email.vue").then(m => m.default || m)
  },
  {
    name: "authentication-management-verify-password-reset",
    path: "/authentication-management/verify-password-reset",
    meta: verify_45password_45resetWigsrPUL4WMeta || {},
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/authentication-management/verify-password-reset.vue").then(m => m.default || m)
  },
  {
    name: "calendar",
    path: "/calendar",
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/calendar.vue").then(m => m.default || m)
  },
  {
    name: "consult",
    path: "/consult",
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/consult.vue").then(m => m.default || m),
    children: [
  {
    name: "consult-billing",
    path: "billing",
    meta: billingmVPNxv6V07Meta || {},
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/consult/billing.vue").then(m => m.default || m)
  },
  {
    name: "consult-emr",
    path: "emr",
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/consult/emr.vue").then(m => m.default || m),
    children: [
  {
    name: "consult-emr-all-records",
    path: "all-records",
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/consult/emr/all-records.vue").then(m => m.default || m)
  },
  {
    name: "consult-emr-dental",
    path: "dental",
    meta: dental3DCD0qisQ8Meta || {},
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/consult/emr/dental.vue").then(m => m.default || m)
  },
  {
    name: "consult-emr-pedia",
    path: "pedia",
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/consult/emr/pedia.vue").then(m => m.default || m)
  },
  {
    name: "consult-emr-soap",
    path: "soap",
    meta: soapzK5fl9BIqcMeta || {},
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/consult/emr/soap.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "consult-encounter",
    path: "encounter",
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/consult/encounter.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard-bookings",
    path: "/dashboard/bookings",
    meta: bookings7CEKXiGargMeta || {},
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/dashboard/bookings.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-bookings-status",
    path: ":status?",
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/dashboard/bookings/[[status]].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard-encounters-type",
    path: "/dashboard/encounters/:type?",
    meta: _91_91type_93_93mnAgwZ8iepMeta || {},
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/dashboard/encounters/[[type]].vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indexPrfZGw7anrMeta || {},
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-notifs",
    path: "/dashboard/notifs",
    meta: notifsWwOVAUHQceMeta || {},
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/dashboard/notifs.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-patient-id",
    path: "/dashboard/patient/:id?",
    meta: _91_91id_93_93z5hILKo6X3Meta || {},
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/dashboard/patient/[[id]].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-patient-form-constants",
    path: "/dashboard/patient/form/constants",
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/dashboard/patient/form/constants.js").then(m => m.default || m)
  },
  {
    name: "dashboard-patient-form",
    path: "/dashboard/patient/form",
    meta: indexSfClL4IUn9Meta || {},
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/dashboard/patient/form/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-patients",
    path: "/dashboard/patients",
    meta: indexxDHH9SwRCoMeta || {},
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/dashboard/patients/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-shared",
    path: "/dashboard/shared",
    meta: indexSJJHn2PvcgMeta || {},
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/dashboard/shared/index.vue").then(m => m.default || m)
  },
  {
    name: "download",
    path: "/download",
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/download.vue").then(m => m.default || m)
  },
  {
    name: "encounter",
    path: "/encounter",
    meta: encounterM90iQRRVrnMeta || {},
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/encounter.vue").then(m => m.default || m),
    children: [
  {
    name: "encounter-assessment",
    path: "assessment",
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/encounter/assessment/index.vue").then(m => m.default || m)
  },
  {
    name: "encounter-billing",
    path: "billing",
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/encounter/billing/index.vue").then(m => m.default || m)
  },
  {
    name: "encounter-billing-index2",
    path: "billing/index2",
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/encounter/billing/index2.vue").then(m => m.default || m)
  },
  {
    name: "encounter-consult-notes",
    path: "consult-notes",
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/encounter/consult-notes/index.vue").then(m => m.default || m)
  },
  {
    name: "encounter-intake-form",
    path: "intake-form",
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/encounter/intake-form/index.vue").then(m => m.default || m)
  },
  {
    name: "encounter-laboratory",
    path: "laboratory",
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/encounter/laboratory/index.vue").then(m => m.default || m)
  },
  {
    name: "encounter-objective",
    path: "objective",
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/encounter/objective/index.vue").then(m => m.default || m)
  },
  {
    name: "encounter-plan",
    path: "plan",
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/encounter/plan/index.vue").then(m => m.default || m)
  },
  {
    name: "encounter-radiology",
    path: "radiology",
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/encounter/radiology/index.vue").then(m => m.default || m)
  },
  {
    name: "encounter-subjective",
    path: "subjective",
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/encounter/subjective/index.vue").then(m => m.default || m)
  },
  {
    name: "encounter-summary-ai-summary",
    path: "summary/ai-summary",
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/encounter/summary/ai-summary.vue").then(m => m.default || m)
  },
  {
    name: "encounter-summary-dental-tab",
    path: "summary/dental-tab",
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/encounter/summary/dental-tab.vue").then(m => m.default || m)
  },
  {
    name: "encounter-summary-encounter-tab",
    path: "summary/encounter-tab",
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/encounter/summary/encounter-tab.vue").then(m => m.default || m)
  },
  {
    name: "encounter-summary",
    path: "summary",
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/encounter/summary/index.vue").then(m => m.default || m)
  },
  {
    name: "encounter-summary-mhx-summary-tab",
    path: "summary/mhx-summary-tab",
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/encounter/summary/mhx-summary-tab.vue").then(m => m.default || m)
  },
  {
    name: "encounter-summary-pedia-tab",
    path: "summary/pedia-tab",
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/encounter/summary/pedia-tab.vue").then(m => m.default || m)
  },
  {
    name: "encounter-triage",
    path: "triage",
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/encounter/triage/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordc6d7lktoOHMeta || {},
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexrlfNtNAlWgMeta || {},
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: intake_45formMckiWFXr8mMeta?.name,
    path: "/intake-form",
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/intake-form.vue").then(m => m.default || m),
    children: [
  {
    name: "intake-form-additional-information",
    path: "additional-information",
    meta: additional_45informationzejJdyYjoLMeta || {},
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/intake-form/additional-information.vue").then(m => m.default || m)
  },
  {
    name: "intake-form-consent-form",
    path: "consent-form",
    meta: consent_45formKPfPXIxdJ2Meta || {},
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/intake-form/consent-form.vue").then(m => m.default || m)
  },
  {
    name: "intake-form-constants",
    path: "constants",
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/intake-form/constants.js").then(m => m.default || m)
  },
  {
    name: "intake-form-contact-information",
    path: "contact-information",
    meta: contact_45informationsAz5oQCTkUMeta || {},
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/intake-form/contact-information.vue").then(m => m.default || m)
  },
  {
    name: "intake-form-email-address",
    path: "email-address",
    meta: email_45addressbLEQlg2gxhMeta || {},
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/intake-form/email-address.vue").then(m => m.default || m)
  },
  {
    name: "intake-form-handle-file",
    path: "handle-file",
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/intake-form/handle-file.js").then(m => m.default || m)
  },
  {
    name: "intake-form",
    path: "",
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/intake-form/index.vue").then(m => m.default || m)
  },
  {
    name: "intake-form-medical-history",
    path: "medical-history",
    meta: medical_45historyZLNelSy74FMeta || {},
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/intake-form/medical-history.vue").then(m => m.default || m)
  },
  {
    name: "intake-form-personal-details",
    path: "personal-details",
    meta: personal_45detailszsP392lzh7Meta || {},
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/intake-form/personal-details.vue").then(m => m.default || m)
  },
  {
    name: "intake-form-privacy-notice",
    path: "privacy-notice",
    meta: privacy_45noticeaIFbb7UvIuMeta || {},
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/intake-form/privacy-notice.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "modules",
    path: "/modules",
    meta: modulesH4ywRr4tTVMeta || {},
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/modules.vue").then(m => m.default || m),
    children: [
  {
    name: "modules-billing-composables",
    path: "billing/composables",
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/modules/billing/composables.js").then(m => m.default || m)
  },
  {
    name: "modules-billing",
    path: "billing",
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/modules/billing/index.vue").then(m => m.default || m)
  },
  {
    name: "modules-billing-transactions",
    path: "billing/transactions",
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/modules/billing/transactions.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "onboarding-doctor-info",
    path: "/onboarding/doctor-info",
    meta: doctor_45infoCC0asVN0WoMeta || {},
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/onboarding/doctor-info.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-specialization",
    path: "/onboarding/specialization",
    meta: specializationye1QR20oWIMeta || {},
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/onboarding/specialization.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-user",
    path: "/onboarding/user",
    meta: userKWgNZW12eOMeta || {},
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/onboarding/user.vue").then(m => m.default || m)
  },
  {
    name: "patient-demo",
    path: "/patient/demo",
    meta: demoSJCYAZz90sMeta || {},
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/patient/demo.vue").then(m => m.default || m)
  },
  {
    name: "print",
    path: "/print",
    meta: printi4ti3wIy2MMeta || {},
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/print.vue").then(m => m.default || m),
    children: [
  {
    name: "print-invoice",
    path: "invoice",
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/print/invoice.vue").then(m => m.default || m)
  },
  {
    name: "print-prescription",
    path: "prescription",
    meta: prescription8btJ9PDmSOMeta || {},
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/print/prescription.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "settings",
    path: "/settings",
    meta: settingsSgKLTUvuU9Meta || {},
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "settings-account",
    path: "account",
    meta: accountFReVfTGqhCMeta || {},
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/settings/account.vue").then(m => m.default || m),
    children: [
  {
    name: "settings-account-education",
    path: "education",
    meta: educationYKAHxRffsiMeta || {},
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/settings/account/education.vue").then(m => m.default || m)
  },
  {
    name: "settings-account-personal",
    path: "personal",
    meta: personalvwR7YpnfHiMeta || {},
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/settings/account/personal.vue").then(m => m.default || m)
  },
  {
    name: "settings-account-professional",
    path: "professional",
    meta: professionalTbM3V9JnRBMeta || {},
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/settings/account/professional.vue").then(m => m.default || m)
  },
  {
    name: "settings-account-security",
    path: "security",
    meta: securityLyKJJoGVkYMeta || {},
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/settings/account/security.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "settings-billing",
    path: "billing",
    meta: billingzWrfySpzXiMeta || {},
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/settings/billing.vue").then(m => m.default || m),
    children: [
  {
    name: "settings-billing-discounts",
    path: "discounts",
    meta: discountsO2z6o9U1WNMeta || {},
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/settings/billing/discounts.vue").then(m => m.default || m)
  },
  {
    name: "settings-billing-promos",
    path: "promos",
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/settings/billing/promos.vue").then(m => m.default || m)
  },
  {
    name: "settings-billing-services",
    path: "services",
    meta: servicesY5NavPOaszMeta || {},
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/settings/billing/services.vue").then(m => m.default || m)
  },
  {
    name: "settings-billing-stripe-connect",
    path: "stripe-connect",
    meta: stripe_45connect5LaAj6oHcDMeta || {},
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/settings/billing/stripe-connect.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "settings-booking",
    path: "booking",
    meta: bookingelyRWyRK9zMeta || {},
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/settings/booking.vue").then(m => m.default || m),
    children: [
  {
    name: "settings-booking-availability",
    path: "availability",
    meta: availability6LHGFC29H6Meta || {},
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/settings/booking/availability.vue").then(m => m.default || m)
  },
  {
    name: "settings-booking-events",
    path: "events",
    meta: eventsBo18DWAVcIMeta || {},
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/settings/booking/events.vue").then(m => m.default || m)
  },
  {
    name: "settings-booking-setup",
    path: "setup",
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/settings/booking/setup.vue").then(m => m.default || m)
  },
  {
    name: "settings-booking-test",
    path: "test",
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/settings/booking/test.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "settings-clinic",
    path: "clinic",
    meta: clinicjtxYkbw1iZMeta || {},
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/settings/clinic.vue").then(m => m.default || m),
    children: [
  {
    name: "settings-clinic-details",
    path: "details",
    meta: detailsWJbukB7DpWMeta || {},
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/settings/clinic/details.vue").then(m => m.default || m)
  },
  {
    name: "settings-clinic-licenses",
    path: "licenses",
    meta: licensesxrsd4oWY5QMeta || {},
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/settings/clinic/licenses.vue").then(m => m.default || m)
  },
  {
    name: "settings-clinic-members",
    path: "members",
    meta: membersbpJqSYUvhBMeta || {},
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/settings/clinic/members.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "settings-dental",
    path: "dental",
    meta: dentalW7JgYA9ZK5Meta || {},
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/settings/dental.vue").then(m => m.default || m),
    children: [
  {
    name: "settings-dental-custom-statuses",
    path: "custom-statuses",
    meta: custom_45statusesuxSigvAOfeMeta || {},
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/settings/dental/custom-statuses.vue").then(m => m.default || m)
  },
  {
    name: "settings-dental-default-statuses",
    path: "default-statuses",
    meta: default_45statusesIW2ie1H61gMeta || {},
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/settings/dental/default-statuses.vue").then(m => m.default || m)
  }
]
  },
  {
    name: diagnosticInbwWdnmamMeta?.name,
    path: "diagnostic",
    meta: diagnosticInbwWdnmamMeta || {},
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/settings/diagnostic.vue").then(m => m.default || m),
    children: [
  {
    name: "settings-diagnostic",
    path: "",
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/settings/diagnostic/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-diagnostic-lab-tests",
    path: "lab-tests",
    meta: lab_45testscVsoXxiFOoMeta || {},
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/settings/diagnostic/lab-tests.vue").then(m => m.default || m)
  },
  {
    name: "settings-diagnostic-radiology-tests",
    path: "radiology-tests",
    meta: radiology_45testsEcZK3g3PqpMeta || {},
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/settings/diagnostic/radiology-tests.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "settings-lab-constants",
    path: "lab/constants",
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/settings/lab/constants.js").then(m => m.default || m)
  },
  {
    name: "settings-lab-tests",
    path: "lab/tests",
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/settings/lab/tests.vue").then(m => m.default || m)
  },
  {
    name: "settings-medical-records",
    path: "medical-records",
    meta: medical_45recordsoBWiMotNVKMeta || {},
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/settings/medical-records.vue").then(m => m.default || m),
    children: [
  {
    name: "settings-medical-records-favorite-prescriptions",
    path: "favorite-prescriptions",
    meta: favorite_45prescriptionsKRgr4DVaPmMeta || {},
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/settings/medical-records/favorite-prescriptions.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "settings-radiology-constants",
    path: "radiology/constants",
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/settings/radiology/constants.js").then(m => m.default || m)
  },
  {
    name: "settings-radiology-tests",
    path: "radiology/tests",
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/settings/radiology/tests.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "sharing",
    path: "/sharing",
    meta: sharingSWHKmhYbcKMeta || {},
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/sharing.vue").then(m => m.default || m)
  },
  {
    name: "signup",
    path: "/signup",
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/signup/index.vue").then(m => m.default || m)
  },
  {
    name: "signup-quick",
    path: "/signup/quick",
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/signup/quick.vue").then(m => m.default || m)
  },
  {
    name: "splashscreen",
    path: "/splashscreen",
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/splashscreen.vue").then(m => m.default || m)
  },
  {
    name: "standalone-authenticate",
    path: "/standalone/authenticate",
    meta: authenticate1w0japLOuPMeta || {},
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/standalone/authenticate.vue").then(m => m.default || m)
  },
  {
    name: "standalone-signup",
    path: "/standalone/signup",
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/standalone/signup.vue").then(m => m.default || m)
  },
  {
    name: "teleconsult",
    path: "/teleconsult",
    meta: teleconsultVypDNCKZ2jMeta || {},
    component: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/pages/teleconsult.vue").then(m => m.default || m)
  }
]