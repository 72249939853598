import { defineNuxtPlugin } from '#imports';
import { VanillaComponents } from '@flavorly/vanilla-components';

export default defineNuxtPlugin(nuxtApp => {
  // You can also override the default options here

  /**
   * NOTE: When overriding Vanilla Components, refer to
   * the Vanilla Components repo and check each component for the
   * associated default configurations that need to be overwritten.
   * Refer to docs for how modifying configs for Vanilla Components work.
   */
  nuxtApp.vueApp.use(VanillaComponents, {
    Input: {
      fixedClasses: {
        input: '',
      },
      classes: {
        input: '',
      },
      variants: {
        primary: {
          classes: {
            input: 'border-[#59A241] focus:border-[#59A241] outline-primary bg-white text-black',
          },
        },
      },
    },
    TagsInput: {
      classes: {
        tag: 'rounded shadow-sm flex items-center cursor-pointer bg-primary-100 m-0.5 h-8 max-w-full text-primary-900 white-space-no text-sm font-semibold',
        trigger: 'w-full h-[100%] appearance-none focus:outline-none bg-white ring-1 ring-inset focus:ring-inset focus:ring-2 ring-neutral-300 focus:ring-primary-600 rounded-lg disabled:opacity-50 disabled:cursor-not-allowed px-2 py-3',
      },
    },
    RichSelect: {
      fixedClasses: {
        trigger: 'w-full h-[100%] appearance-none focus:outline-none',
        clearButton: 'z-10',
      },
      classes: {
        // dropdown: 'w-full bg-neutral-400',
        option: 'w-full disabled:opacity-50 cursor-pointer disabled:cursor-not-allowed text-sm font-normal px-3 py-2 focus:outline-none',
        optionWrapper: 'focus:outline-none',
        selectedOption: 'bg-neutral-100 focus:outline-none overflow-hidden',
        highlightedOption: 'hover:text-primary-900 bg-neutral-100',
        clearButton: 'absolute flex items-center justify-center text-black rounded mr-3 hover:bg-neutral-100 focus:ring-opacity-70 top-1/2 right-0 transform -translate-y-1/2 focus:outline-none focus:ring-2 dark:focus:ring-offset-neutral-900 focus:ring-primary-600 w-5 h-5 transition duration-100 ease-in-out',
        clearButtonIcon: 'fill-current h-4 w-4',
        tag: 'rounded shadow-sm flex items-center cursor-pointer bg-primary-100 dark:border m-0.5 h-8 max-w-full text-primary-900 white-space-no text-sm font-semibold focus:outline-none focus:ring-2 dark:focus:ring-offset-neutral-900 focus:ring-opacity-50 focus:ring-primary-600 transition duration-100 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed',
        tagDeleteButton: 'transition duration-100 ease-in-out focus:outline-none focus:ring-2 focus:ring-opacity-50 focus:ring-primary-600 -ml-1.5 h-full hover:shadow-sm inline-flex items-center px-2 rounded-r text-primary-400 focus:text-white hover:bg-neutral-100 focus:bg-primary-500',
        searchWrapper: 'inline-block w-full px-3 py-2',
      },
      variants: {
        primary: {
          classes: {
            trigger: 'bg-white text-black border-0 ring-1 ring-inset focus:ring-inset focus:ring-2 ring-neutral-300 focus:ring-primary-600 focus-visible:ring-primary-600 relative flex items-center justify-between w-full focus:z-10 cursor-pointer text-sm transition duration-100 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed px-4 py-3', // refers to text-input
            dropdown: 'z-50 overflow-hidden bg-white border border-1 border-neutral-300 rounded-md shadow-lg',
            // option: 'w-full disabled:opacity-50 text-white cursor-pointer disabled:cursor-not-allowed text-sm font-normal px-3 py-2 focus:outline-none',
            searchInput: 'inline-block w-full shadow-inner focus:ring-opacity-70 text-sm px-4 py-2.5 text-black placeholder-neutral-500/60 ring-1 ring-inset focus:ring-inset focus:ring-2 ring-neutral-300 focus:ring-primary-600 focus-visible:ring-primary-600 border-1 rounded-lg shadow bg-white outline-primary',
            searchingText: 'flex items-center justify-center px-3 py-2 text-sm text-neutral-500 text-center space-x-2',
          },
        },
      },
    },
  });
});
