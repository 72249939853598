import { defineNuxtPlugin } from '#imports';
import PrimeVue from 'primevue/config';

export default defineNuxtPlugin(nuxtApp => {
  // You can also override the default options here
  nuxtApp.vueApp.use(PrimeVue, {
    unstyled: false,
    ripple: true,
    components: {
      prefix: 'Prime',
      include: ['MultiSelect', 'FileUpload', 'Dropdown', 'ColorPicker', 'Chips'],
    },
    css: ['primevue/resources/themes/lara-light-teal/theme.css'],
  });
});
